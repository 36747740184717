import React from 'react'
import {
  ArrayField,
  Datagrid,
  NumberField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import { ShowAction } from '../../components/actions'


const BannerShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props} />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <h3>유저</h3>
        <ArrayField source="userInfo">
          <Datagrid>
            <NumberField source="id" />
            <TextField source="name" />
            <ShowButton basePath={`${props.basePath.replace('reports', 'users')}`} />
          </Datagrid>
        </ArrayField>


        <h3>동네 이야기</h3>
        <ArrayField source="postInfo">
          <Datagrid>
            <NumberField source="id" />
            <TextField source="content" />
            <ShowButton basePath={`${props.basePath.replace('reports', 'posts')}`} />
          </Datagrid>
        </ArrayField>

        <h3>함께해요</h3>
        <ArrayField source="meetInfo">
          <Datagrid>
            <NumberField source="id" />
            <TextField source="title" />
            <ShowButton basePath={`${props.basePath.replace('reports', 'meets')}`} />
          </Datagrid>
        </ArrayField>


      </SimpleShowLayout>
    </Show>
  )
}

export default BannerShow
