import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  NumberField,
  ArrayField,
  Datagrid
} from 'react-admin'
import { Table, TableCell, TableContainer, TableHead, TableRow, } from "@material-ui/core"

import { ShowListActions } from "../../components/actions"

const MeetsShow = (props) => {
  return (

    < Show {...props} actions={< ShowListActions record={props} />}>
      <SimpleShowLayout>
        <h2>함께해요 정보</h2>
        <TableContainer style={{ border: "1px solid black", width: "500px" }}>
          <Table>
            <TableHead style={{ borderBottom: "1px solid black", }}>
              <TableRow >
                <TableCell>최소 나이</TableCell>
                <TableCell>최대 나이</TableCell>
                <TableCell>애견 크기</TableCell>
                <TableCell>성별</TableCell>
              </TableRow>
            </TableHead>
            <TableRow >
              <TableCell><TextField source="ageStart" /></TableCell>
              <TableCell><TextField source="ageEnd" /></TableCell>
              <TableCell><TextField source="weightType" /></TableCell>
              <TableCell><TextField source="gender" /></TableCell>
            </TableRow>
          </Table>
        </TableContainer>
        <NumberField source="id" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="content" sortable={false} />
        <TextField source="address" sortable={false} />
        <DateField source="meetDate" showTime locales='ko' />
        <DateField source="createdAt" locales='ko' />

        <h2>참석자</h2>
        <NumberField source="participantsCount" sortable={false} />
        <ArrayField source="participants">
          <Datagrid>
            <TextField source="id" />
            <TextField source="nickname" />
            <TextField source="dogName" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show >
  )
}

export default MeetsShow
