import Report from '@material-ui/icons/Report'
import ReportsList from './ReportsList'
import ReportsShow from './ReportsShow'

const Reports = {
  list: ReportsList,
  show: ReportsShow,
  icon: Report,
}


export default Reports
