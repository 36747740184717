import MenuBookIcon from '@material-ui/icons/MenuBook';
import PostsShow from './PostsShow'
import PostsList from './PostsList'

const Posts = {
    list: PostsList,
    show: PostsShow,
    icon: MenuBookIcon,
}


export default Posts
