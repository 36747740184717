import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
} from 'react-admin'
import { required } from '../../validators'
import { EditAction } from '../../components/actions'
import { EditToolbar } from '../../components/toolbars'

const EditAdmin = (props) => (
  <Edit {...props} actions={<EditAction />}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput disabled source='id' />
      <TextInput source="name" validate={required} />
      <TextInput source="password" type='password' />
    </SimpleForm>
  </Edit>
)

export default EditAdmin
