import React from 'react'
import {
  BooleanInput,
  Edit,
  SimpleForm
} from 'react-admin'
import { EditAction } from '../../components/actions'
import { EditToolbar } from '../../components/toolbars'
import { required } from "../../validators"

const BannerEdit = (props) => {
  return (
    <Edit {...props} actions={<EditAction />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <BooleanInput source="isApproved" validate={required} />
      </SimpleForm>
    </Edit>
  )
}

export default BannerEdit
