import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import {
  TopToolbar,
  Datagrid,
  List,
  TextField,
  BooleanField,
} from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const Actions = ({ basePath, }) => (
  <TopToolbar>
  </TopToolbar>
)

const BannerList = withStyles(styles)(({ classes, ...props }) => {
  return (
    <List {...props}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      actions={<Actions />}
      bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="username" sortable={false} />
        <TextField source="managertype" sortable={false} />
        <TextField source="filed" sortable={false} />
        <TextField source="onelineintro" sortable={false} />
        <TextField source="servicelocal" sortable={false} />
        <TextField source="category" sortable={false} />
        <BooleanField source="isApproved" sortable={false} />
      </Datagrid>
    </List>
  )
})

export default BannerList
