import { CREATE, GET_ONE, GET_LIST, DELETE, UPDATE, GET_MANY } from 'react-admin'
import { uploadToS3 } from "./fileHandler"

export async function request(apiUrl, type, params) {
  let url = apiUrl + '/stores'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      await uploadToS3(params.data, 'images', 'image', null)
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_LIST:
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      options.method = 'PUT'
      url += `/${params.id}`
      await uploadToS3(params.data, 'images', 'image', null)
      delete params.data.imagePath
      delete params.data.createdAt
      delete params.data.topicStr
      options.data = params.data
      break
    case 'UPLOAD':
      url += `/upload`
      options.method = 'POST'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return { url, ...options }
}

export function response(res, type, params) {
  let ret = {}
  const { data, status } = res
  switch (type) {

    case CREATE:
      if (status === 201) ret = { data: { id: params.id } }
      break
    case 'UPLOAD':
      if (status === 201) {
        window.location.reload()
      } else {
        window.alert("업로드에 실패 ")
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) {
        ret = { data: [data] }
      } else ret = { data }
      break
    case GET_LIST:
      if (data) {

        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i].topic === 'together') data.data[i].topic = '애견동반시설'
          else data.data[i].topic = '반려시설'

          if(data.data[i].category && data.data[i].category.length >0 ){
            data.data[i].categoryStr = ""
            data.data[i].category.forEach(item =>{
              if (item === 'restaurant') data.data[i].categoryStr += '식당'
              else if (item === 'cafe') data.data[i].categoryStr += ' 카페'
              else if (item === 'accommodation') data.data[i].categoryStr += ' 숙소'
              else if (item === 'facility') data.data[i].categoryStr += ' 시설'
              else if (item === 'park') data.data[i].categoryStr += ' 공원'
              else if (item === 'travel') data.data[i].categoryStr += ' 여행'
              else if (item === 'hospital') data.data[i].categoryStr += ' 병원'
              else if (item === 'salon') data.data[i].categoryStr += ' 미용실'
              else if (item === 'store') data.data[i].categoryStr += ' 스토어'
              else if (item === 'dogCafe') data.data[i].categoryStr += ' 애견카페'
              else if (item === 'dogHotel') data.data[i].categoryStr += ' 애견호텔'
              else if (item === 'dogFacility') data.data[i].categoryStr += ' 애견시설'
            })
          }

          console.log(data)

        }



        ret = data
      }
      break
    case GET_ONE:
      if (data) {
        if (data.images && data.images.length) {
          for (let i = 0; i < data.images.length; i++) {
            data.images[i] = {
              img: convertImagePath(data.images[i])
            }
          }
        }

        if (data.topic === 'together') data.topicStr = '애견동반시설'
        else data.topicStr = '반려시설'

        if(data.category && data.category.length >0 ){
          data.categoryStr = ""
          data.category.forEach(item =>{
            if (item === 'restaurant') data.categoryStr += ' 식당'
            else if (item === 'cafe') data.categoryStr += ' 카페'
            else if (item === 'accommodation') data.categoryStr += ' 숙소'
            else if (item === 'facility') data.categoryStr += ' 시설'
            else if (item === 'park') data.categoryStr += ' 공원'
            else if (item === 'travel') data.categoryStr += ' 여행'
            else if (item === 'hospital') data.categoryStr += ' 병원'
            else if (item === 'salon') data.categoryStr += ' 미용실'
            else if (item === 'store') data.categoryStr += ' 스토어'
            else if (item === 'dogCafe') data.categoryStr += ' 애견카페'
            else if (item === 'dogHotel') data.categoryStr += ' 애견호텔'
            else if (item === 'dogFacility') data.categoryStr += ' 애견시설'
          })
        }

        console.log(data)


        ret = { data }
      }
      break
    case UPDATE:
      if (status === 201) ret = { data: { id: params.id } }
      break

    case DELETE:
      if (status === 204) ret = { data: { id: params.id } }
      break
    default:
      ret = { data }
      break
  }
  return ret
}

export const convertImagePath = (path) => {
  return `${process.env.REACT_APP_FILE_URL}/${encodeURIComponent(path)}`
}

