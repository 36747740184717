import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import UsersShow from './UsersShow'
import UsersList from './UsersList'

const Presenters = {
    list: UsersList,
    show: UsersShow,
    icon: SupervisedUserCircleIcon,
}


export default Presenters
