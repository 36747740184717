import { CREATE, DELETE, GET_LIST, GET_ONE, UPDATE, } from 'react-admin'
import moment from 'moment-timezone'
moment().tz("Asia/Seoul").format()

export function request(apiUrl, type, params) {
  let url = `${apiUrl}/walkGroups`
  let options = {}

  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break

    case GET_LIST:
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return { url, ...options }
}

export function response(res, type, params) {
  let ret = {}
  const { data } = res
  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_ONE:
      if (data) {

        data.address = `${data.address1 ? data.address1 : ""} ${data.address2} ${data.address3} ${data.address4}`

        if (data.image) data.image = convertImagePath(data.image)

        for (let i = 0; i < data.participants.length; i++) {
          if (data.participants[i].role === 'master') data.participants[i].role = '모임장'
          else data.participants[i].role = '일반'
        }

        ret = { data }
      }
      break

    case GET_LIST:
      if (data) {

        for (let i = 0; i < data.data.length; i++) {
          data.data[i].address =
            `${data.data[i].address1 ? data.data[i].address1 : ""}
             ${data.data[i].address2} 
             ${data.data[i].address3} 
             ${data.data[i].address4} `
        }

        ret = data
      }
      break

    case CREATE:
      ret = { data: { id: 0 } }
      break

    case DELETE:
      ret = { data }
      break

    default:
      if (data) ret = { data }
      break
  }
  return ret
}

export const convertImagePath = (path) => {
  return `${process.env.REACT_APP_FILE_URL}/${encodeURIComponent(path)}`
}