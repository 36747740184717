import {dataProvider} from '../../App'

const axios = require('axios')

const fileHandler = (file, subKey, type) =>
  new Promise((resolve, reject) => {
    const rawFile = subKey ? file[subKey].rawFile : file.rawFile
    getPreSignedUrl(type, rawFile.type)
      .then((result) => putObject(result, rawFile))
      .then((path) => resolve(path))
      .catch((e) => reject(e))
  })

export async function getPreSignedUrl(type, mimeType) {
  try {
    const {
      data: {url, path}
    } = await dataProvider('UPLOAD', 'files', {data: {type, mimeType}})
    return {url, path}
  } catch (e) {
    throw e
  }
}

export async function putObject(info, file) {
  try {
    await axios.put(info.url, file, {headers: {'Content-Type': 'image/jpg'}})

    return info.path
  } catch (e) {
    throw e
  }
}

export async function uploadToS3(data, key, type, subKey) {
  if (!data[key]) return data
  const arr = data[key]
  if (!Array.isArray(arr)) {
    if (arr.id) return data
    if (!arr.rawFile) {
      data[key] = data[key].path
      return data
    }
    return fileHandler(arr, subKey, type).then((ret) => {
      data[key] = ret
      return data
    })
  }
  if (arr && arr.length) {
    const formerFiles = arr
      .filter((f) => {
        const rawFile = subKey ? f[subKey].rawFile : f.rawFile
        return !(rawFile instanceof File)
      })
      .map((file) => {
        // if(typeof file === 'object'){
        // const data = {...file}
        // delete data[subKey]
        // }
        return file
      })
    const newFiles = arr.filter((f) => {
      const rawFile = subKey ? f[subKey].rawFile : f.rawFile
      return rawFile instanceof File
    })
    const transformedFiles = []
    for (let i = 0; i < newFiles.length; i++) {
      const f = newFiles[i]
      const uploaded = await fileHandler(f, subKey, type)
      transformedFiles.push(uploaded)
    }
    data[key] = [...transformedFiles, ...formerFiles]
  }
  return data
}
