import { withStyles } from '@material-ui/core/styles'
import { dataProvider } from "../../App"
import React from 'react'
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  List,
  TextField,
  DateField,
  Button
} from 'react-admin'
import Papa from 'papaparse'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

function App() {
  const [file, setFile] = React.useState()

  var commonConfig = { delimiter: ",", header: true };

  const fileReader = new FileReader()

  const handleOnChange = (e) => {
    setFile(e.target.files[0])
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const csvOutput = event.target.result;
        Papa.parse(csvOutput, {
          ...commonConfig,
          complete: async (result) => {

            console.log(result.data)
            // const arr = []
            // for (let key in result.data) {
            //   const temp = {
            //     id: result.data[key][0],
            //     question: result.data[key][1],
            //     score: result.data[key][2],
            //   }

            //   arr.push(temp)
            // }
            // console.log(arr)
            await dataProvider('UPLOAD', 'stores', { data: result.data })

          }
        })

      }
      fileReader.readAsText(file);
    }
  }

  return (
    <div style={{ textAlign: "center" }}>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <Button label="업로드"
          onClick={(e) => {
            handleOnSubmit(e);
          }}>
        </Button>
      </form>
    </div>
  );
}


const Actions = ({ basePath, }) => (

  <TopToolbar>
    <App />
    <CreateButton basePath={basePath} />
  </TopToolbar>
)

const StoresList = withStyles(styles)(({ classes, ...props }) => {

  return (
    <List {...props}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<Actions />}
      perPage={25}
      bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="topic" sortable={false} />
        <TextField source="categoryStr" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="subtitle" sortable={false} />
        <TextField source="address" sortable={false} />
        <DateField source="createdAt" locales='ko' showTime sortable={false} />
      </Datagrid>
    </List>
  )
})

export default StoresList
