import React from 'react'
import {
  ArrayField,
  Datagrid, DateField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField,
  UrlField
} from 'react-admin'
import { ShowAction } from '../../components/actions'


const StoresShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props} />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <ArrayField source='images'>
          <ImageField source='images' src='img' />
        </ArrayField>
        <TextField source="title" sortable={false} />
        <TextField source="topicStr" sortable={false} />
        <TextField source="categoryStr" sortable={false} />
        <TextField source="keyword" sortable={false} />
        <TextField source="address" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="openTime" sortable={false} />
        <UrlField source="link" sortable={false} />
        <TextField source="description" sortable={false} />
        <ArrayField source='hashtags'>
          <Datagrid>
            <div>#
              <TextField source='name'></TextField>
            </div>
          </Datagrid>
        </ArrayField>
        <DateField source="createdAt" locales='ko' showTime sortable={false} />
      </SimpleShowLayout>
    </Show>
  )
}

export default StoresShow
