import React from 'react'
import {TopToolbar, ListButton, EditButton} from 'react-admin'

const ShowActions = (props) => (
  <TopToolbar>
    <ListButton {...props} />
    <EditButton {...props} record={props.data} />
  </TopToolbar>
)

export default ShowActions
