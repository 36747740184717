import React from 'react'
import {
  DateField,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import { ShowAction } from '../../components/actions'


const BannerShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props} />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <TextField source="title" sortable={false} />
        <TextField source="contents" sortable={false} />
        <DateField source="createdAt" locales='ko' showTime sortable={false} />
      </SimpleShowLayout>
    </Show>
  )
}

export default BannerShow
