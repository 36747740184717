import QuestionAnswer from '@material-ui/icons/QuestionAnswer'
import FaqsList from './FaqsList'
import FaqsShow from './FaqsShow'
import FaqsEdit from './FaqsEdit'
import FaqsCreate from './FaqsCreate'

const Faqs = {
  create: FaqsCreate,
  list: FaqsList,
  show: FaqsShow,
  edit: FaqsEdit,
  icon: QuestionAnswer,
}


export default Faqs
