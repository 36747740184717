import Map from '@material-ui/icons/MapRounded'
import StoresList from './StoresList'
import StoresShow from './StoresShow'
import StoresEdit from './StoresEdit'
import StoresCreate from './StoresCreate'

const Stores = {
  create: StoresCreate,
  list: StoresList,
  show: StoresShow,
  edit: StoresEdit,
  icon: Map,
}


export default Stores
