import React from 'react'
import {
  ArrayInput,
  Create,
  FileInput,
  ImageField,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import { required } from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'

let choices = []
function selectTopic(value) {
  if (value === 'together') {
    choices = [
      { id: 'restaurant', name: '식당' },
      { id: 'cafe', name: '카페' },
      { id: 'accommodation', name: '숙소' },
      { id: 'facility', name: '시설' },
      { id: 'park', name: '공원' },
      { id: 'travel', name: '여행' },]
  } else {
    choices = [
      { id: 'hospital', name: '병원' },
      { id: 'salon', name: '미용실' },
      { id: 'store', name: '스토어' },
      { id: 'dogCafe', name: '애견카페' },
      { id: 'hotel', name: '애견호텔' },
      { id: 'dogFacility', name: '애견시설' },
    ]
  }
}


const StoresCreate = (props) => {

  const [value, setValue] = React.useState()


  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm
        toolbar={<CreateToolbar />}>
        <SelectInput
          onChange={event => {
            setValue(event.target.value)
            selectTopic(value)
          }}
          source='topic'
          value={value}
          choices={[
            { id: 'together', name: '에견동반시설' },
            { id: 'companion', name: '반려시설' },
          ]} validate={required} ></SelectInput>
        <SelectArrayInput source='category' choices={choices} validate={required}></SelectArrayInput>
        <FileInput source="images" src="img" multiple={true} >
          <ImageField source="img" />
        </FileInput>

        <TextInput source='title' validate={required}></TextInput>
        <TextInput source='subtitle'></TextInput>
        <TextInput source='keyword'></TextInput>
        <TextInput source='address' validate={required}></TextInput>
        <TextInput source='roadAddress' validate={required}></TextInput>
        <TextInput source='phone'></TextInput>
        <TextInput source='openTime'></TextInput>
        <TextInput source='link'></TextInput>
        <ArrayInput source='hashtags'>
          <SimpleFormIterator disableReordering  >
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source='description'></TextInput>

      </SimpleForm>
    </Create >
  )
}

export default StoresCreate
