import React from 'react'
import { Create, TextInput, SimpleForm } from 'react-admin'
import { required } from '../../validators'
import CreateActions from '../../components/actions/CreateActions'
import CreateToolbar from '../../components/toolbars/CreateToolbar'

const BannerCreate = (props) => {
  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm
        toolbar={<CreateToolbar />}>
        <TextInput source="title" required={required} />
        <TextInput source="contents" required={required} />
      </SimpleForm>
    </Create>
  )
}

export default BannerCreate
