export const request = (apiUrl, type, params) => {
  let url = `${apiUrl}/auth`
  const options = {}
  switch (type) {
    case 'LOGIN':
      options.method = 'POST'
      options.data = { username: params.data.username, password: params.data.password }
      break
    case 'REFRESH':
      url += '/refresh'
      options.method = 'POST'
      options.data = params.data
      break
    default:
      break
  }
  return { url, ...options }
}

export const response = (res, type) => {
  let ret = {}
  const { data } = res
  switch (type) {

    default:
      if (data) ret = { data }
      break
  }
  return ret
}
