import { CREATE, GET_ONE, GET_LIST, DELETE, UPDATE, } from 'react-admin'
export function request(apiUrl, type, params) {
  let url = apiUrl + '/managerUsers'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return { url, ...options }
}

export function response(res, type, params) {
  let ret = {}
  const { data, status } = res
  switch (type) {
    case GET_LIST:
      if (data) {

        for (let i = 0; i < data.data.length; i++) {
          if (!data.data[i].managerUserId) data.data[i].isApproved = false
          else data.data[i].isApproved = true


          if (data.data[i].category === 'walk') data.data[i].category = '산책'
          else if (data.data[i].category === 'salon') data.data[i].category = '미용'
          else if (data.data[i].category === 'training') data.data[i].category = '훈련'
          else if (data.data[i].category === 'medicine') data.data[i].category = '의료'
          else if (data.data[i].category === 'snack') data.data[i].category = '간식'
          else if (data.data[i].category === 'care') data.data[i].category = '돌봄'
          else if (data.data[i].category === 'etc') data.data[i].category = '기타'



        }

        ret = data
      }
      break
    case GET_ONE:
      if (data) {
        data.profileimage = convertImagePath(data.profileimage)

        if (!data.managerUserId) data.isApproved = false
        else data.isApproved = true

        if (data.category === 'walk') data.category = '산책'
        else if (data.category === 'salon') data.category = '미용'
        else if (data.category === 'training') data.category = '훈련'
        else if (data.category === 'medicine') data.category = '의료'
        else if (data.category === 'snack') data.category = '간식'
        else if (data.category === 'care') data.category = '돌봄'
        else if (data.category === 'etc') data.category = '기타'

        ret = { data }
      }
      break
    case DELETE:
      if (status === 204) ret = { data: { id: params.id } }
      break
    case UPDATE:
      if (status === 200) ret = { data: { id: params.id } }
      break
    default:
      ret = { data }
      break
  }
  return ret
}

export const convertImagePath = (path) => {
  return `${process.env.REACT_APP_FILE_URL}/${encodeURIComponent(path)}`
}


