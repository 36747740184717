import React from 'react'
import {
  ArrayInput,
  Edit,
  FileInput,
  ImageField, SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import { EditAction } from '../../components/actions'
import { EditToolbar } from '../../components/toolbars'
import { required } from '../../validators'


const choices = [

  { id: 'restaurant', name: '식당' },
  { id: 'cafe', name: '카페' },
  { id: 'accommodation', name: '숙소' },
  { id: 'facility', name: '시설' },
  { id: 'park', name: '공원' },
  { id: 'travel', name: '여행' },
  { id: 'hospital', name: '병원' },
  { id: 'salon', name: '미용실' },
  { id: 'store', name: '스토어' },
  { id: 'dogCafe', name: '애견카페' },
  { id: 'dogHotel', name: '애견호텔' },
  { id: 'dogFacility', name: '애견시설' },
]


const StoresEdit = (props) => {

  return (
    <Edit {...props} actions={<EditAction />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <SelectInput
          source='topic'
          choices={[
            { id: 'together', name: '에견동반시설' },
            { id: 'companion', name: '반려시설' },
          ]} ></SelectInput>
        <SelectArrayInput source='category' choices={choices}></SelectArrayInput>
        <FileInput source="images" src="img" multiple={true} validate={required}>
          <ImageField source="img" />
        </FileInput>
        <TextInput source='title' validate={required}></TextInput>
        <TextInput source='subtitle'></TextInput>
        <TextInput source='keyword'></TextInput>
        <TextInput source='address' validate={required}></TextInput>
        <TextInput source='roadAddress' validate={required}></TextInput>
        <TextInput source='phone'></TextInput>
        <TextInput source='openTime'></TextInput>
        <TextInput source='link'></TextInput>
        <ArrayInput source='hashtags'>
          <SimpleFormIterator disableReordering  >
            <TextInput source="name" />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source='description'></TextInput>
      </SimpleForm>
    </Edit>
  )
}

export default StoresEdit
