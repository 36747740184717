import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AdminCreate from './AdminCreate'
import AdminEdit from './AdminEdit'
import AdminShow from './AdminShow'
import AdminList from './AdminList'

const Admins = {
    list: AdminList,
    create: AdminCreate,
    edit: AdminEdit,
    show: AdminShow,
    icon: SupervisedUserCircleIcon,
}


export default Admins
