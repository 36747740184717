import {TopToolbar, ListButton} from 'react-admin'
import React from 'react'

const CreateActions = ({basePath}) => (
  <TopToolbar>
    <ListButton basePath={basePath}/>
  </TopToolbar>
)

export default CreateActions
