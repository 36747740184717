import PeopleSharp from '@material-ui/icons/PeopleSharp'
import ManagerUsersList from './ManagerUsersList'
import ManagerUsersShow from './ManagerUsersShow'
import ManagerUsersEdit from './ManagerUsersEdit'

const ManagerUsers = {
  list: ManagerUsersList,
  show: ManagerUsersShow,
  edit: ManagerUsersEdit,
  icon: PeopleSharp,
}


export default ManagerUsers
