import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  ArrayField,
  Datagrid,
  BooleanField,
  NumberField,
  ShowButton,
} from 'react-admin'

import { ShowListActions } from "../../components/actions"

const WalkGroupsShow = (props) => {

  return (

    < Show {...props} actions={< ShowListActions record={props} />}>
      <SimpleShowLayout>
        <h2>멍모임 정보</h2>
        <TextField source="title" />
        <ImageField source="image" />
        <TextField source="content" />
        <TextField source="name" />
        <BooleanField source="status" />


        <TextField source="address" />
        <DateField source="createdAt" locales='ko' />

        <h2>모임 참여자</h2>
        <TextField source="capacity" />
        <TextField source="participantsCount" />
        <ArrayField source="participants">
          <Datagrid>
            <NumberField source="id" />
            <TextField source="name" />
            <TextField source="role" />
            <ShowButton basePath={`${props.basePath.replace('walkGroups', 'users')}`} />
          </Datagrid>
        </ArrayField>

        <h2>일정 </h2>
        <ArrayField source="assembles">
          <Datagrid>
            <DateField source="assembleDate" showTime locales='ko' />
            <TextField source="address" />
            <TextField source="name" />
            <NumberField source="participantCount" />
            <ShowButton basePath={`${props.basePath.replace('walkGroups', 'assembles')}`} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show >
  )
}

export default WalkGroupsShow
