import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  ImageField
} from 'react-admin'

import { ShowListActions } from "../../components/actions"

const UsersShow = (props) => {
  return (
    <Show {...props} actions={<ShowListActions record={props} />}>
      <SimpleShowLayout>
        <h2>유저</h2>
        <NumberField source="id" sortable={false} />
        <TextField source="nickname" sortable={false} />
        <TextField source="email" sortable={false} />
        <TextField source="socialType" sortable={false} />
        <TextField source="socialAccountId" sortable={false} />
        <TextField source="phone" sortable={false} />
        <TextField source="nickname" sortable={false} />
        <ImageField source="profileImage" sortable={false} />
        <TextField source="address" sortable={false} />
        <BooleanField source="isMarketing" sortable={false} />
        <BooleanField source="isNotification" sortable={false} />
        <BooleanField source="isDeleted" />

        <h2>강아지</h2>
        <TextField source="dogType" sortable={false} />
        <TextField source="name" sortable={false} />
        <TextField source="gender" sortable={false} />
        <DateField source="birth" locales='ko' sortable={false} />
        <BooleanField source="neutering" sortable={false} />
        <TextField source="weight" sortable={false} />
        <ImageField source="dogImage" sortable={false} />
        <DateField source="createdAt" locales='ko' sortable={false} />
      </SimpleShowLayout>
    </Show>
  )
}

export default UsersShow
