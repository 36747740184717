import React from 'react'
import { TopToolbar, ListButton, } from 'react-admin'

const ShowListActions = (props) => (
  <TopToolbar>
    <ListButton {...props} />
  </TopToolbar>
)

export default ShowListActions
