import polyglotI18nProvider from 'ra-i18n-polyglot'
import localeMessages from './ko'
import lodashGet from 'lodash/get'

export default polyglotI18nProvider((locale) => {
  if (locale === 'ko') {
    return import('./ko').then((messages) => {
      return {
        ...messages.default,
        translate: key => lodashGet(messages, key),
      }
    })
  }
  return localeMessages
}, 'en')
