import * as React from 'react'
import { Admin, Resource, defaultTheme } from 'react-admin'
import { createBrowserHistory } from 'history'
import _dataProvider from './providers/data'
import i18nProvider from './providers/i18n'
import { authProvider } from './providers/authProvider'
import { Layout } from './components'
import { auth, admins, users, posts, meets, walkGroups, assembles, stores, managerUsers, faqs, notices, reports } from './pages'
import { createTheme } from '@material-ui/core/styles'

export const dataProvider = _dataProvider(process.env.REACT_APP_URL)
export const history = createBrowserHistory()
const theme = createTheme({
  ...defaultTheme
})

const App = () => {
  return (
    <Admin
      title={process.env.REACT_APP_TITLE}
      dataProvider={dataProvider}
      authProvider={authProvider}
      layout={Layout}
      history={history}
      loginPage={auth}
      i18nProvider={i18nProvider}
      theme={theme}
    >
      {(permissions) => {
        const resources = []
        resources.push(<Resource name="admins" {...admins} />)
        resources.push(<Resource name="users" {...users} />)
        resources.push(<Resource name="posts" {...posts} />)
        resources.push(<Resource name="meets" {...meets} />)
        resources.push(<Resource name="walkGroups" {...walkGroups} />)
        resources.push(<Resource name="assembles" {...assembles} />)
        resources.push(<Resource name="stores" {...stores} />)
        resources.push(<Resource name="managerUsers" {...managerUsers} />)
        resources.push(<Resource name="faqs" {...faqs} />)
        resources.push(<Resource name="notices" {...notices} />)
        resources.push(<Resource name="reports" {...reports} />)
        if (permissions === 'master') {
        }
        return resources
      }}
    </Admin>

  )
}

export default App
