import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  ArrayField,
  Datagrid,
  NumberField,
  ShowButton,
} from 'react-admin'

import { ShowListActions } from "../../components/actions"

const AssemblesShow = (props) => {

  return (

    < Show {...props} actions={< ShowListActions record={props} />}>
      <SimpleShowLayout>
        <h2>일정 정보</h2>
        <TextField source="title" />
        <TextField source="content" />
        <ImageField source="profileImage" />

        <TextField source="nickname" />
        <TextField source="userCityName" />
        <DateField source="assembleDate" showTime locales='ko' />


        <TextField source="address" />
        <DateField source="createdAt" locales='ko' />

        <h2>참여자</h2>
        <ArrayField source="users">
          <Datagrid>
            <NumberField source="id" />
            <TextField source="name" />
            <ShowButton basePath={`${props.basePath.replace('assembles', 'users')}`} />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show >
  )
}

export default AssemblesShow
