import MenuBookIcon from '@material-ui/icons/MenuBook';
import WalkGroupsShow from './WalkGroupsShow'
import WalkGroupsList from './WalkGroupsList'

const WalkGroups = {
    list: WalkGroupsList,
    show: WalkGroupsShow,
    icon: MenuBookIcon,
}


export default WalkGroups
