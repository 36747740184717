import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import MeetsShow from './MeetsShow'
import MeetsList from './MeetsList'

const Meets = {
    list: MeetsList,
    show: MeetsShow,
    icon: MeetingRoomIcon,
}


export default Meets
