import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Datagrid, DateField, List, TextField, NumberField, Filter, Pagination, } from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListFilter = props => (
  <Filter {...props}>
    {/* <SearchInput source="search" placeholder="닉네임으로 검색" alwaysOn /> */}
  </Filter>
)

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const MeetsList = withStyles(styles)(({ classes, ...props }) => {
  return (
    <>
      <List {...props}
        filters={<ListFilter />}
        pagination={<PostPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={50}
        exporter={false}
        bulkActionButtons={false}
        classes={{ main: classes.main }}
      >
        <Datagrid rowClick="show" classes={{ row: classes.row }}>
          <NumberField source="id" sortable={false} />
          <TextField source="name" sortable={false} />
          <TextField source="title" sortable={false} />
          <TextField source="content" sortable={false} />
          <TextField source="address" sortable={false} />
          <TextField source="ageStart" sortable={false} />
          <TextField source="ageEnd" sortable={false} />
          <TextField source="weightType" sortable={false} />
          <TextField source="gender" sortable={false} />
          <NumberField source="participantsCount" sortable={false} />
          <DateField source="meetDate" showTime locales='ko' />
          <DateField source="createdAt" locales='ko' />
        </Datagrid>
      </List>
    </>
  )
})

export default MeetsList
