import { CREATE, GET_ONE, GET_LIST, DELETE, UPDATE, } from 'react-admin'
export function request(apiUrl, type, params) {
  let url = apiUrl + '/admins'
  let options = {}
  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case GET_LIST:
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order: order,
        start: (page - 1) * perPage,
        perPage: perPage,
      }
      break
    case UPDATE:
      url += `/${params.id}`
      options.method = 'PUT'
      options.data = params.data
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return { url, ...options }
}

export function response(res, type, params) {
  let ret = {}
  const { data, status } = res
  switch (type) {
    case CREATE:
      if (status === 201) ret = { data: { id: params.id } }
      break
    case GET_LIST:
      if (data) ret = data
      break
    case GET_ONE:
      if (data) ret = { data }
      break
    case DELETE:
      if (status === 204) ret = { data: { id: params.id } }
      break
    case UPDATE:
      if (status === 200) ret = { data: { id: params.id } }
      break
    default:
      ret = { data }
      break
  }
  return ret
}


