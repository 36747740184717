import MenuBookIcon from '@material-ui/icons/MenuBook';
import AssemblesShow from './AssemblesShow'

const Assembles = {
    show: AssemblesShow,
    icon: MenuBookIcon,
}


export default Assembles
