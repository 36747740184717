import {number as createNumberValidator, required as createRequiredValidator, regex} from 'react-admin'

export const required = createRequiredValidator('errors.common.required')
export const number = createNumberValidator('errors.common.number')
export const userName = regex(/^[0-9a-zA-Z]{4,100}$/, 'errors.auth.notValidUserName')
export const password = regex(/^[0-9a-zA-Z!@#$%^&*()?+-_~=\\/]{8,40}$/, 'errors.auth.notValidPassword')
export const phone = regex(/^01([016789]?)([0-9]{3,4})([0-9]{4})$/, 'errors.common.phone')
export const mallId = regex(/^[0-9a-zA-Z]{1,50}$/, 'errors.companies.mallId')
export const mdId = regex(/^[0-9a-zA-Z]{1,50}$/, 'errors.companies.mdId')
export const limit20 = regex(/^.{1,10}$/, 'errors.tags.limit')
