import React from 'react'
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ImageField,
  ArrayField,
  Datagrid
} from 'react-admin'

import { ShowListActions } from "../../components/actions"

const PostsShow = (props) => {
  return (

    < Show {...props} actions={< ShowListActions record={props} />}>
      <SimpleShowLayout>
        <h2>게시글</h2>
        <TextField source="content" />
        <ArrayField source="images">
          <ImageField source="images" src="img" />
        </ArrayField>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="commentCount" />
        <TextField source="likeCount" />
        <TextField source="address" />
        <DateField source="createdAt" locales='ko' />

        <h2>댓글</h2>
        <ArrayField source="comments">
          <Datagrid>
            <TextField source="name" />
            <TextField source="content" />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </Show >
  )
}

export default PostsShow
