import React from 'react'
import {
  BooleanField,
  ImageField,
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin'
import { ShowAction } from '../../components/actions'


const BannerShow = (props) => {
  return (
    <Show {...props} actions={<ShowAction record={props} />}>
      <SimpleShowLayout>
        <TextField source="id" sortable={false} />
        <TextField source="username" sortable={false} />
        <TextField source="phonenumber" sortable={false} />
        <TextField source="managertype" sortable={false} />
        <ImageField source='profileimage' />
        <TextField source="filed" sortable={false} />
        <TextField source="onelineintro" sortable={false} />
        <TextField source="servicelocal" sortable={false} />
        <TextField source="managertype" sortable={false} />
        <TextField source="category" sortable={false} />
        <BooleanField source="isApproved" sortable={false} />
      </SimpleShowLayout>
    </Show>
  )
}

export default BannerShow
