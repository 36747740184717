import React from 'react'
import {useSelector} from 'react-redux'
import {Layout, Sidebar} from 'react-admin'
import {Menu, AppBar} from '../../components'
import {darkTheme, lightTheme} from '../../assets/themes'
import { makeStyles } from '@material-ui/core/styles'

const useSidebarStyles = makeStyles({
  drawerPaper: {
    width: '180px',
    marginTop: '10px',
  }
})

const CustomSidebar = (props) => {
  const classes = useSidebarStyles();
  return (<Sidebar {...props} classes={classes}/>)
}

const LayoutComponent = (props) => {
  const theme = useSelector((state) => state.theme === 'dark' ? darkTheme : lightTheme)
  return <Layout {...props} sidebar={CustomSidebar} appBar={AppBar} menu={Menu} theme={theme} />
}

export default LayoutComponent
