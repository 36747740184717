import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import { EditAction } from '../../components/actions'
import { EditToolbar } from '../../components/toolbars'
import { required } from "../../validators"

const BannerEdit = (props) => {
  return (
    <Edit {...props} actions={<EditAction />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="title" required={required} />
        <TextInput source="contents" required={required} />
      </SimpleForm>
    </Edit>
  )
}

export default BannerEdit
