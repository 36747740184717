import React, {useState} from 'react'
import {ThemeProvider} from '@material-ui/styles'
import {createTheme} from '@material-ui/core/styles'
import {Login} from '../../components'
import {lightTheme} from '../../assets/themes'
import {useAuthProvider, useNotify} from 'react-admin'
import {useHistory} from 'react-router-dom'

const Auth = (props) => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const authProvider = useAuthProvider()
  const history = useHistory()

  const handleLogin = async (auth) => {
    setLoading(false)
    try {
      await authProvider.login({...auth})
      history.push('/users')
    } catch (error) {
      setLoading(false)
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.data.message
          ? 'ra.auth.sign_in_error'
          : 'ra.auth.sign_role_error',
        'warning'
      )
    }
  }
  return (
    <ThemeProvider theme={createTheme(lightTheme)}>
      <Login {...props} handleSubmit={handleLogin} loading={loading} />
    </ThemeProvider>
  )
}

export default Auth
