export const menus = [
  {
    title: '사용자 관리',
    items: ['users',],
    names: ['사용자 관리'],
    roles: [
      []
    ]
  },
  {
    title: '커뮤니티 관리',
    items: ['posts', 'meets'],
    names: ['동네 이야기', '함께해요'],
    roles: [
      []
    ]
  },
  {
    title: '멍모임 관리',
    items: ['walkGroups',],
    names: ['멍모임',],
    roles: [
      []
    ]
  },
  {
    title: '플레이스 관리',
    items: ['stores'],
    names: ['플레이스'],
    roles: [
      []
    ]
  },
  {
    title: '전문가 관리',
    items: ['managerUsers'],
    names: ['전문가 관리'],
    roles: [
      []
    ]
  },
  {
    title: 'F&Q',
    items: ['faqs',],
    names: ['자주하는질문'],
    roles: [
      []
    ]
  },
  {
    title: '공지사항',
    items: ['notices',],
    names: ['공지사항'],
    roles: [
      []
    ]
  },
  {
    title: '신고 관리',
    items: ['reports',],
    names: ['신고 관리'],
    roles: [
      []
    ]
  },
  {
    title: '관리자 관리',
    items: ['admins'],
    names: ['관리자 관리'],
    roles: [
      []
    ]
  }
]
