import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { Datagrid, DateField, List, TextField, NumberField, Filter, SearchInput, Pagination, BooleanField, } from 'react-admin'

const styles = theme => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }
})

const ListFilter = props => (
  <Filter {...props}>
    <SearchInput source="search" placeholder="닉네임으로 검색" alwaysOn />
  </Filter>
)

const PostPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />

const UsersList = withStyles(styles)(({ classes, ...props }) => {
  return (
    <>
      <List {...props}
        filters={<ListFilter />}
        pagination={<PostPagination />}
        sort={{ field: 'id', order: 'DESC' }}
        perPage={50}
        exporter={false}
        bulkActionButtons={false}
        classes={{ main: classes.main }}
      >
        <Datagrid rowClick="show" classes={{ row: classes.row }}>
          <NumberField source="id" sortable={false} />
          <TextField source="nickname" sortable={false} />
          <TextField source="dogName" sortable={false} />
          <TextField source="email" sortable={false} />
          <TextField source="address" />
          <DateField source="createdAt" locales='ko' />
          <BooleanField source="isDeleted" />
        </Datagrid>
      </List>
    </>
  )
})

export default UsersList
