import Notice from '@material-ui/icons/NotificationImportant'
import NoticesList from './NoticesList'
import NoticesShow from './NoticesShow'
import NoticesEdit from './NoticesEdit'
import NoticesCreate from './NoticesCreate'

const Notices = {
  create: NoticesCreate,
  list: NoticesList,
  show: NoticesShow,
  edit: NoticesEdit,
  icon: Notice,
}


export default Notices
